<template lang="pug">
.template-chooser-page-loading-skeleton
  .loading-row.mb-5(v-for="index in rows")
    .d-flex.justify-content-between.align-items-end.mb-5(v-if="header")
      vue-skeleton-loader(type="rect" width="10%" height="32px" rounded)
      vue-skeleton-loader(type="rect" width="10%" height="12px" rounded)
    .row
      .col(v-for="index in cols")
        vue-skeleton-loader.mb-3(type="rect" width="100%" height="250px" rounded)
        vue-skeleton-loader(type="rect" width="45%" height="12px" rounded)
</template>

<script>
  export default {
    props: {
      rows: { type: Number, default: 3 },
      cols: { type: Number, default: 3 },
      header: { type: Boolean, default: false },
    },
  };
</script>
